import React, { memo } from 'react';

import { ProductsIndexTableBodyProps } from './ProductsIndexTableBody.types';

import { ProductsIndexTableRow } from '../ProductsIndexTableRow';

function ProductsIndexTableBody({
  companyNanoId,
  products,
  productsSet,
  productsCacheKeys,
  productsSetCacheKeys,
  productsFilters,
  changeProductsFilters,
  onLightboxOpen,
  onSelectedProductsSidebarOpen,
  onSelectedProductsSidebarClose,
  updateProductsSetCache,
  updateProductCache,
  onProductsSelect,
  onProductEditButtonMouseEnter,
  onProductAiSearch
}: ProductsIndexTableBodyProps) {
  return (
    <tbody>
      {products.map((product) => (
        <ProductsIndexTableRow
          companyNanoId={companyNanoId}
          key={product.uuid}
          product={product}
          productsSet={productsSet}
          productsCacheKeys={productsCacheKeys}
          productsSetCacheKeys={productsSetCacheKeys}
          productsFilters={productsFilters}
          changeProductsFilters={changeProductsFilters}
          onLightboxOpen={onLightboxOpen}
          onSelectedProductsSidebarOpen={onSelectedProductsSidebarOpen}
          onSelectedProductsSidebarClose={onSelectedProductsSidebarClose}
          onProductAiSearch={onProductAiSearch}
          updateProductsSetCache={updateProductsSetCache}
          updateProductCache={updateProductCache}
          onProductsSelect={onProductsSelect}
          onProductEditButtonMouseEnter={onProductEditButtonMouseEnter}
        />
      ))}
    </tbody>
  );
}

export default memo<ProductsIndexTableBodyProps>(ProductsIndexTableBody);
